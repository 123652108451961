//NODE MODULES

//KPP COMPONENTS
import LinkImage from "../helpers/LinkImage";

//CONFIG
import { navRoutes } from "../../config/nav";

//ASSETS
import featureImg from "../../assets/img/hero/feature.jpg";
import aboutImg from "../../assets/img/hero/about.jpg";
import contactImg from "../../assets/img/hero/contact.jpg";
import NewsSlider from "./NewsSlider";

const Hero = () => {
	return (
		<section id="hero" className="grid-wrapper hero-wrapper">
			<div className="grid-wrapper padding-none hero-feature">
				<div aria-hidden="true"></div>
				<div aria-hidden="true"></div>
				<div aria-hidden="true"></div>
				<div aria-hidden="true"></div>
				<div aria-hidden="true"></div>
				<div aria-hidden="true"></div>
				<div className="hero-feature-image">
					<img src={featureImg} alt="" />
				</div>
			</div>
			<div className="backgroundColor-kppCream hero-news">
				<NewsSlider />
			</div>
			<div className="backgroundColor-kppCream hero-about">
				<LinkImage slug={navRoutes.about} title="About" image={aboutImg} />
			</div>
			<div className="backgroundColor-kppCream hero-contact">
				<LinkImage slug={navRoutes.contact} title="Contact" image={contactImg} />
			</div>
		</section>
	);
};

export default Hero;
