//REACT
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

//RECOIL
import { RecoilRoot } from "recoil";

//ROUTER
import { RouterProvider } from "react-router-dom";
import { router } from "./config/nav";

//APOLLO
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}`,
	headers: {
		Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_KEY}`,
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<ApolloProvider client={apolloClient}>
			<RecoilRoot>
				<RouterProvider router={router} />
			</RecoilRoot>
		</ApolloProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
