import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

import { NewsItem } from "./";

//GRAPHQL
const QUERY = gql`
	query MoreNewsArticles($current: String!) {
		newsCollection(limit: 3, where: { title_not: $current }) {
			items {
				sys {
					id
					publishedAt
				}
				title
				heroImage {
					url
				}
				alternativePublishDate
			}
		}
	}
`;

const MoreNewsArticles = ({ title, current }) => {
	const { data, errors, loading } = useQuery(QUERY, {
		variables: {
			current: current,
		},
	});

	const [newsArticles, setNewsArticles] = useState([]);

	useEffect(() => {
		data && data.newsCollection.items.length && setNewsArticles(data.newsCollection.items);
	}, [data]);

	return (
		<section className="moreItems-wrapper circlePattern">
			<div className="paddingTop-large text-center fontSize-medium">{title}</div>
			<div className="grid columns-3 gap-large content-width paddingTop-medium paddingBottom-large">
				{newsArticles.length &&
					newsArticles.map((newsItem, idx) => {
						const delay = 200 * (idx + 1);
						return (
							<NewsItem
								key={`newsItem-${newsItem.sys.id}`}
								id={newsItem.sys.id}
								slug={newsItem.slug}
								title={newsItem.title}
								image={newsItem.heroImage.url}
								date={newsItem.alternativePublishDate ? newsItem.alternativePublishDate : newsItem.sys.publishedAt}
								project={newsItem.project}
								animationDelay={delay}
							/>
						);
					})}
			</div>
		</section>
	);
};

export default MoreNewsArticles;
