//Node Modules

//Assets
import { ReactComponent as Logo } from "../../assets/img/logo/kpp-logo-full.svg";
import { ReactComponent as InstagramIcon } from "../../assets/img/social/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/img/social/linkedin.svg";

//Helpers
import { email, insta, linkedIn, phone, phoneUI } from "../../config/contact/contact";
import { LinkUnderline } from "../helpers";
import { navRoutes } from "../../config/nav/navRoutes";

const AppFooter = () => {
	return (
		<footer className="appFooter">
			<div className="appFooter-content flexVert aItems-center">
				<div className="appFooter-logo">
					<Logo />
				</div>
				<div className="appFooter-links flex aItems-center gapCol-medium gapRow-small marginTop-large">
					<LinkUnderline side="bottom" destination={`tel: ${phone}`} text={phoneUI} wrapperClasses="link-light" />
					<span className="divider fontColour-charcoalLight">{`//`}</span>
					<LinkUnderline side="bottom" destination={`mailto: ${email}`} text={email} wrapperClasses="link-light" />
					<span className="divider fontColour-charcoalLight">{`//`}</span>
					<div className="flex aItems-center gap-medium">
						<a href={linkedIn.link} target="blank">
							<LinkedInIcon className="socialIcon socialIcon-animate" />
						</a>
						<a href={insta.link} target="blank">
							<InstagramIcon className="socialIcon socialIcon-animate" />
						</a>
					</div>
				</div>
				<div className="appFooter-legal marginTop-xlarge flex gap-small jContent-center">
					<LinkUnderline side="top" destination={navRoutes.privacy} text="Privacy Policy" />
					<span className="divider fontColour-charcoalLight">{`//`}</span>
					<span>&copy; {new Date().getFullYear()} KPP Architects Ltd.</span>
				</div>
			</div>
		</footer>
	);
};

export default AppFooter;
