//Node Modules
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import classNames from "classnames";

//Config
import { contactURL, emailValidation, telephoneValidation } from "../../config/contact/contact";

const Form = () => {
	const [submitMsg, setSubmitMsg] = useState("Send Message");
	const [formSent, setFormSent] = useState(false);

	const defaultValues = {
		name: "",
		company: "",
		email: "",
		telephone: "",
		message: "",
	};

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({ defaultValues, mode: "onTouched", reValidateMode: "onChange" });

	const submitContactForm = async (data) => {
		try {
			setSubmitMsg("Sending Message...");
			await axios.post(contactURL, data, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			setFormSent(true);
			reset();
		} catch (err) {
			console.error(err);
		} finally {
			setSubmitMsg("Send Message");
		}
	};

	useEffect(() => {
		if (formSent) {
			showFormDelay();
		}
	}, [formSent]);

	const showFormDelay = () => {
		setTimeout(() => setFormSent(false), 5000);
	};

	// Form Error Message
	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="fontColour-error fontSize-small">{errors[name].message}</span>;
	};

	const messageLength = watch("message").length;

	return (
		<>
			<div className="fontFamily-feature fontColour-cream fontSize-large marginBottom-large">Send Us a Message</div>
			{formSent ? (
				<div>
					<p className="fontSize-medium">Thanks for getting in touch. We'll get back to you as soon as possible.</p>
				</div>
			) : (
				<form onSubmit={handleSubmit(submitContactForm)}>
					<div className="grid columns-2 gap-medium gapCol-large">
						<div className="formField">
							<label htmlFor="fullname">Name: *</label>
							<input
								type="text"
								name="fullname"
								className={classNames({ error: errors.fullname })}
								{...register("fullname", {
									required: { value: true, message: "Your name is required" },
									maxLength: { value: 100, message: "Name cannot be more than 100 characters" },
								})}
							/>
							{getFormErrorMessage("fullname")}
						</div>
						<div className="formField">
							<label htmlFor="company">Company:</label>
							<input type="text" name="company" {...register("company", { maxLength: { value: 100, message: "Company cannot be more than 100 characters" } })} />
							{getFormErrorMessage("company")}
						</div>
						<div className="formField">
							<label htmlFor="email">Email: *</label>
							<input
								type="text"
								name="email"
								className={classNames({ error: errors.email })}
								{...register("email", {
									required: { value: true, message: "Your email address is required" },
									maxLength: { value: 100, message: "Email address cannot be more than 100 characters" },
									pattern: { value: emailValidation, message: "Please enter a valid email address" },
								})}
							/>
							{getFormErrorMessage("email")}
						</div>
						<div className="formField">
							<label htmlFor="number">Telephone:</label>
							<input
								type="text"
								name="number"
								{...register("number", {
									maxLength: { value: 20, message: "Telephone number cannot be more than 20 characters" },
									pattern: { value: telephoneValidation, message: "Please enter a valid telephone number" },
								})}
							/>
							{getFormErrorMessage("number")}
						</div>
						<div className="formField span-col2">
							<div className="flex jContent-spaceBetween label">
								<label htmlFor="message">Message: *</label>
								<span>{messageLength}/255</span>
							</div>
							<textarea
								name="message"
								rows="5"
								className={classNames({ error: errors.message })}
								{...register("message", {
									required: { value: true, message: "A message is required" },
									maxLength: { value: 255, message: "Message cannot be more than 255 characters" },
								})}
							/>
							{getFormErrorMessage("message")}
						</div>
					</div>
					<button type="submit" className="marginTop-medium" disabled={isSubmitting}>
						{submitMsg}
					</button>
				</form>
			)}
		</>
	);
};

export default Form;
