export const contactURL = "https://22384v4n97.execute-api.eu-west-1.amazonaws.com/prod/contact";

export const phone = "+441132390460";
export const phoneUI = "+44 (0)113 239 0460";

export const email = "architects@kpp-leeds.co.uk";

export const insta = {
	handle: "kpparchitectslds",
	link: "https://www.instagram.com/kpparchitectslds/",
};
export const twitter = {
	handle: "@ArchitectsKpp",
	link: "https://twitter.com/ArchitectsKpp",
};
export const linkedIn = {
	handle: "kpp-architects",
	link: "https://www.linkedin.com/company/kpp-architects/",
};

export const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const telephoneValidation = /^[0-9+()-\s]*$/;
