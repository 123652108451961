//Node Modules

//My Components
import { PageHeader } from "../components/helpers";
import { Hero, Sectors } from "../components/home";

//3rd Party Components

//Atoms

//Helpers

const Home = () => {
	return (
		<>
			<PageHeader />
			<Hero />
			<Sectors />
		</>
	);
};

export default Home;
