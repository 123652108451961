const Quote = ({ quote, author }) => {
	return (
		<div className="quote">
			<div className="flexVert gap-small">
				<p>{quote}</p>
				<span className="text-right">- {author}</span>
			</div>
		</div>
	);
};

export default Quote;
