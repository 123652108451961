import { gql } from "@apollo/client";

export const GET_SECTORS = gql`
	query Sectors {
		sectorCollection(limit: 12, order: [priority_ASC]) {
			items {
				sys {
					id
				}
				name
				image {
					url
				}
				slug
			}
		}
	}
`;
