//NODE MODULES
import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ImageViewer from "awesome-image-viewer";
import { HiMagnifyingGlassPlus } from "react-icons/hi2";

//KPP COMPONENTS
import { NewsHeader, MoreNewsArticles } from "../components/news";

//HELPERS
import useScrollBlock from "../helpers/scrollBlock";

//GRAPHQL
const QUERY = gql`
	query NewsArticle($newsArticleId: String!, $isPreview: Boolean = false) {
		news(id: $newsArticleId, preview: $isPreview) {
			sys {
				id
				publishedAt
			}
			title
			description {
				json
			}
			heroImage {
				url
				title
			}
			imagesCollection {
				items {
					url
					title
				}
			}
			project {
				title
			}
			alternativePublishDate
		}
	}
`;

const NewsArticle = () => {
	const [blockScroll] = useScrollBlock();
	const location = useLocation();
	const { newsArticleId } = location.state;

	const [newsArticle, setNewsArticle] = useState(null);

	const { data, errors, loading } = useQuery(QUERY, {
		variables: {
			newsArticleId: newsArticleId,
			isPreview: process.env.REACT_APP_CONTENT_PREVIEW === "true" ? true : false,
		},
	});

	useEffect(() => {
		blockScroll();
	}, []);

	useEffect(() => {
		data && data.news && setNewsArticle(data.news);
	}, [data]);

	const viewImages = (idx) => {
		const newsImages = newsArticle.imagesCollection.items.map((img) => {
			return { mainUrl: img.url, thumbnailUrl: img.url, description: img.title };
		});
		new ImageViewer({
			images: newsImages,
			currentSelected: idx,
			stretchImages: true,
			isZoomable: false,
		});
	};

	return (
		newsArticle && (
			<>
				<article className="news marginBottom-xlarge">
					<NewsHeader
						title={newsArticle.title}
						heroImage={newsArticle.heroImage}
						date={DateTime.fromISO(newsArticle.alternativePublishDate ? newsArticle.alternativePublishDate : newsArticle.sys.publishedAt).toFormat("dd MMM yyyy")}
						details={[
							{
								label: "Date",
								value: DateTime.fromISO(newsArticle.alternativePublishDate ? newsArticle.alternativePublishDate : newsArticle.sys.publishedAt).toFormat(
									"dd MMM yyyy"
								),
							},
							{ label: "Project", value: newsArticle.project && newsArticle.project.title },
							{ label: "Tags", value: "Design Guide, Interior, Another Tag" },
						]}
					/>
					<div className="newsArticleContent marginTop-xlarge">
						<div className="content-width">
							<div className="grid columns-2 gap-large">
								<section className="description">{documentToReactComponents(newsArticle.description.json)}</section>
								<section className="gallery">
									{newsArticle.imagesCollection.items.map((img, idx) => (
										<div className="imgHover-wrapper" key={img.title} onClick={() => viewImages(idx)}>
											<img src={img.url} alt={img.title} />
											<div className="imgHover">
												<HiMagnifyingGlassPlus />
											</div>
										</div>
									))}
								</section>
							</div>
						</div>
					</div>
				</article>
				<MoreNewsArticles title="More News Stories" current={newsArticle.title} />
			</>
		)
	);
};

export default NewsArticle;
