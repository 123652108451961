import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { useQuery, gql } from "@apollo/client";

//GRAPHQL QUERY
const QUERY = gql`
	query PageMeta($slug: String!) {
		pageTitlesCollection(limit: 1, where: { slug: $slug }) {
			items {
				title
				description
			}
		}
	}
`;

const PageHeader = () => {
	const location = useLocation();

	const [pageMeta, setPageMeta] = useState({
		title: "KPP Architects",
		desc: "KPP Architects are an award-winning architectural practice; based in Leeds since 1969, providing full Architectural services in connection with a wide range of projects throughout the U.K.",
	});

	const { data } = useQuery(QUERY, {
		variables: {
			slug: location.pathname.slice(1),
		},
	});

	useEffect(() => {
		if (data && data.pageTitlesCollection.items.length > 0) {
			const pageData = data.pageTitlesCollection.items[0];
			setPageMeta({
				title: pageData.title,
				desc: pageData.description,
			});
		}
	}, [data]);

	return (
		<Helmet>
			<title>{pageMeta.title} :: KPP Architects</title>
			<meta name="description" content={pageMeta.desc} />
		</Helmet>
	);
};

export default PageHeader;
