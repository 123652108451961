//Node Modules

//My Components
import { Map, Form } from "../components/contact";
import { LinkUnderline } from "../components/helpers";

//3rd Party Components

//HELPERS
import { PageHeader } from "../components/helpers";

//Helpers & Config
import { email, phone, phoneUI } from "../config/contact/contact";

const Home = () => {
	return (
		<>
			<PageHeader />
			<div className="grid-wrapper pageHeader-wrapper aItems-center">
				<div id="pageHeader-title">
					<h1>Get In Touch</h1>
				</div>
				<div id="pageHeader-desc">
					<p className="text-maxWidth">Want to talk to us about an upcoming project and what we could offer, we'd love to hear from you.</p>
				</div>
			</div>
			<div className="grid-wrapper contact-wrapper">
				<div id="phoneBlock" className="container flex aItems-center jContent-center paddingBlock-xlarge paddingInline-medium backgroundColor-kppCream-light">
					<LinkUnderline side="bottom" destination={`tel: ${phone}`} text={phoneUI} wrapperClasses="link-dark" linkClasses="fontSize-large fontFamily-feature" />
				</div>
				<div id="emailBlock" className="container flex aItems-center jContent-center paddingBlock-xlarge paddingInline-medium backgroundColor-kppCream">
					<LinkUnderline
						side="bottom"
						destination={`mailto: ${email}`}
						text={
							<>
								architects@
								<br />
								kpp-leeds.co.uk
							</>
						}
						wrapperClasses="link-dark"
						linkClasses="fontSize-large fontFamily-feature"
					/>
				</div>
				<div id="addressBlock" className="container flex aItems-center jContent-center paddingBlock-xlarge paddingInline-medium backgroundColor-kppCharcoal">
					<LinkUnderline
						side="left"
						destination="https://www.google.com/maps/place/Kilmartin+Plowman+%26+Partners/@53.8370362,-1.6418448,15z/data=!4m6!3m5!1s0x48795896f6353125:0x239c8b9eef1feccb!8m2!3d53.8370362!4d-1.6418448!16s%2Fg%2F1tf8smb2"
						text={
							<>
								Lodge House
								<br />
								12 Town St
								<br />
								Horsforth
								<br />
								Leeds
								<br />
								LS18 4RJ
							</>
						}
						wrapperClasses="link-light"
						linkClasses="fontSize-medium fontFamily-feature textLineHeight-large"
					/>
				</div>
				<div id="formBlock" className="container backgroundColor-kppCharcoal paddingBlock-large paddingInline-large">
					<Form />
				</div>
				<div id="mapBlock" className="backgroundColor-kppCream">
					<Map />
				</div>
			</div>
		</>
	);
};

export default Home;
