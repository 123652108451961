import { Link } from "react-router-dom";
import { DateTime } from "luxon";

//Assets
import { ReactComponent as CicleLink } from "../../assets/img/circle-link.svg";

const NewsItem = ({ id, slug, title, image, date, project, animationDelay }) => {
	return (
		<div className="newsItem-wrapper">
			<Link to={`/news/${slug}`} state={{ newsArticleId: id }} className="newsItem news-reveal" style={{ animationDelay: `${animationDelay}ms` }}>
				<div className="newsItem-image overflow-hidden">
					<img src={`${image}?w=500`} alt={`Thumbnail for ${title}`} />
				</div>
				<div className="flex jContent-spaceBetween aItems-center padding-small title-wrapper">
					<div className="title">
						<div className="subTitle fontSize-small flex gap-small">
							<span>{DateTime.fromISO(date).toFormat("dd MMM yyyy")}</span>
							{project && (
								<>
									<span>//</span>
									<span>{project.title}</span>
								</>
							)}
						</div>
						<span className="limitLines-1">{title}</span>
					</div>
					<CicleLink />
				</div>
			</Link>
		</div>
	);
};

export default NewsItem;
