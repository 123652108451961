import { useRecoilState } from "recoil";
import { showMobileMenuAtom } from "../../atoms/layout";

const Hamburger = () => {
	const [showMobileMenu, setShowMobileMenu] = useRecoilState(showMobileMenuAtom);

	return (
		<button type="button" className="noBorder nav hamburger hamburger--squeeze" onClick={() => setShowMobileMenu((prevState) => !prevState)} data-active={showMobileMenu}>
			<span className="hamburger-box">
				<span className="hamburger-inner"></span>
			</span>
		</button>
	);
};

export default Hamburger;
