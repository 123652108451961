// Node Modules
import { createBrowserRouter } from "react-router-dom";

//Pages
import App from "../../App";
import { Home, About, Projects, Project, News, NewsArticle, Contact, Privacy } from "../../pages";

//Config
import { navRoutes } from "./";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: navRoutes.home,
				element: <Home />,
			},
			{
				path: navRoutes.about,
				element: <About />,
			},
			{
				path: navRoutes.projects,
				children: [
					{
						path: navRoutes.projectsSector,
						element: <Projects />,
					},
					{
						path: navRoutes.projectItem,
						element: <Project />,
					},
				],
			},
			{
				path: navRoutes.news,
				element: <News />,
			},
			{
				path: navRoutes.newsItem,
				element: <NewsArticle />,
			},
			{
				path: navRoutes.contact,
				element: <Contact />,
			},
			{
				path: navRoutes.privacy,
				element: <Privacy />,
			},
		],
	},
]);
