//Node Modules
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { gsap } from "gsap";

//My Components
import { LinkUnderline } from "../helpers";

//3rd Party Components

//Atoms
import { showMobileMenuAtom } from "../../atoms/layout";

//Helpers
import { navRoutes } from "../../config/nav";

const AppNavMobile = ({ role }) => {
	const mobileMenu = useRef();
	const { pathname } = useLocation();

	const [currentLocation, setCurrentLocation] = useState("/");
	const [isLoaded, setIsLoaded] = useState(false);
	const showMobileMenu = useRecoilValue(showMobileMenuAtom);

	useEffect(() => {
		const path = `/${pathname.split("/")[1]}`;
		// console.log("Path ::", path);
		setCurrentLocation(path);
	}, [pathname]);

	useLayoutEffect(() => {
		let ctx = null;

		const mobileMenu = document.getElementById("mobileMenu");
		ctx = gsap.context(() => {
			// use scoped selectors
			if (showMobileMenu) {
				gsap.fromTo(mobileMenu, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 });
				gsap.fromTo(".mobileLink", { y: -0.15, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.1, delay: 0.2 });
				setIsLoaded(true);
			} else if (isLoaded) {
				gsap.fromTo(".mobileLink", { y: 0, opacity: 1 }, { y: 0.15, opacity: 0, stagger: 0.1 });
				gsap.fromTo(mobileMenu, { opacity: 1, scale: 1 }, { opacity: 0, scale: 0, delay: 0.7 });
			}
		}, mobileMenu);

		return () => ctx.revert();
	}, [showMobileMenu, isLoaded]);

	return (
		<nav id="mobileMenu" className={`appNavMobile appNav-${role}`} ref={mobileMenu} data-visible={showMobileMenu}>
			<LinkUnderline side="bottom" destination={navRoutes.about} text="About" wrapperClasses="mobileLink" active={currentLocation.startsWith(navRoutes.about)} />
			<LinkUnderline
				side="bottom"
				destination={`${navRoutes.projects}/sector/all`}
				text="Projects"
				wrapperClasses="mobileLink"
				active={currentLocation.startsWith(navRoutes.projects)}
			/>
			<LinkUnderline side="bottom" destination={navRoutes.avExt} text="KPP AV" wrapperClasses="mobileLink" active={currentLocation.startsWith(navRoutes.avExt)} external />
			<LinkUnderline side="bottom" destination={navRoutes.news} text="News" wrapperClasses="mobileLink" active={currentLocation.startsWith(navRoutes.news)} />
			<LinkUnderline side="bottom" destination={navRoutes.contact} text="Contact" wrapperClasses="mobileLink" active={currentLocation.startsWith(navRoutes.contact)} />
		</nav>
	);
};

export default AppNavMobile;
