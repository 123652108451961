//NODE MODULES
import { useQuery } from "@apollo/client";
import { GET_SECTORS } from "../../graphql/home";

//KPP COMPONENTS
import { Sector } from "./";

const Sectors = () => {
	const { data, loading } = useQuery(GET_SECTORS);

	return (
		<section id="sectors" className="grid-wrapper sectors-wrapper marginTop-large">
			<div className="grid-wrapper padding-none sectors-sectors">
				{loading
					? [...Array(9)].map((_, i) => <div key={`sectorPlaceholder-${i}`} className="sectors-sectorItem"></div>)
					: data.sectorCollection.items.map((sector) => <Sector key={sector.sys.id} sectorTitle={sector.name} slug={sector.slug} image={sector.image.url} />)}
			</div>
			<div className="backgroundColor-kppCharcoal sectors-description">
				<h2 className="marginBottom-small fontFamily-feature fontColour-creamDarkest">Sectors</h2>
				<p className="fontSize-large fontColour-cream textLineHeight-small">KPP provide a full range of services across all architectural sectors.</p>
			</div>
		</section>
	);
};

export default Sectors;
