import { Link } from "react-router-dom";

const Sector = ({ sectorTitle, slug, image }) => {
	return (
		<Link to={`/projects/sector/${slug}`} state={{ sectorId: slug }} className={`sectors-sectorItem aspect-1_1`}>
			<div className="sector-image">
				<img src={image} alt={`${sectorTitle} Background`} />
			</div>
			<span className="sector-title">{sectorTitle}</span>
		</Link>
	);
};

export default Sector;
