//NODE MODULES
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

//GRAPHQL
import { useLazyQuery, gql } from "@apollo/client";

//KPP COMPONENTS
import { PageHeader } from "../components/helpers";
import { ProjectItem } from "../components/projects";

//ASSETS
import allSectorImg from "../assets/img/hero/feature.jpg";

//GRAPHQL
const QUERY_LIMIT = 18;
const QUERY = gql`
	query Sectors($sectorSlug: String!, $isAll: Boolean!, $isPreview: Boolean = false, $queryLimit: Int, $querySkip: Int) {
		sectorList: sectorCollection(limit: 12, preview: $isPreview, order: [priority_ASC]) {
			items {
				sys {
					id
				}
				name
				image {
					url
				}
				slug
			}
		}
		sector: sectorCollection(limit: 1, preview: $isPreview, where: { slug: $sectorSlug }) @skip(if: $isAll) {
			items {
				name
				summary
			}
		}
		allProjects: projectCollection(limit: $queryLimit, order: sys_firstPublishedAt_DESC, skip: $querySkip, preview: $isPreview) @include(if: $isAll) {
			items {
				sys {
					id
				}
				title
				slug
				completion
				location
				heroImage {
					url
				}
			}
		}
		sectorProjects: projectCollection(limit: $queryLimit, where: { sector: { slug: $sectorSlug } }, order: sys_firstPublishedAt_DESC, skip: $querySkip, preview: $isPreview)
			@skip(if: $isAll) {
			items {
				sys {
					id
				}
				title
				slug
				completion
				location
				heroImage {
					url
				}
			}
		}
	}
`;

const Projects = () => {
	const observer = useRef(null);
	const urlParams = useParams();

	//Local State
	const [header, setHeader] = useState();
	const [projects, setProjects] = useState([]);
	const [querySkip, setQuerySkip] = useState(0);
	const [showMore, setShowMore] = useState(false);

	//GraphQL Setup
	const [getProjects, { loading, error, data }] = useLazyQuery(QUERY, {
		variables: {
			sectorSlug: urlParams.sectorId,
			isAll: urlParams.sectorId === "all" ? true : false,
			isPreview: process.env.REACT_APP_CONTENT_PREVIEW === "true" ? true : false,
			queryLimit: QUERY_LIMIT,
			querySkip: querySkip,
		},
	});

	// useEffect(() => {
	// 	getProjects();
	// }, []);

	useEffect(() => {
		setShowMore(false);
		setHeader(null);
		setProjects([]);
		setQuerySkip(0);
		getProjects();
	}, [urlParams]);

	useEffect(() => {
		let projectsData = [];
		let headerData = {};

		if (data && data.allProjects) {
			projectsData = data.allProjects.items;
			headerData = {
				title: "All Projects",
				desc: "",
			};
			setShowMore(true);
		} else if (data && data.sector) {
			projectsData = data.sectorProjects.items;
			headerData = {
				title: data.sector.items[0].name,
				desc: data.sector.items[0].summary,
			};
			setShowMore(true);
		}

		if (data && projectsData.length === 0) {
			const loadMoreTrigger = document.getElementById("loadMore");
			observer.current.unobserve(loadMoreTrigger);
		}

		setHeader(headerData);
		setProjects((prevState) => [...prevState, ...projectsData]);
	}, [data]);

	useEffect(() => {
		let loadMoreTrigger = null;

		if (showMore) {
			//create new instance and pass a callback function
			observer.current = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						const intersecting = entry.isIntersecting;
						if (intersecting) {
							console.log("Load More...");
							getProjects();
							setQuerySkip((prevState) => prevState + QUERY_LIMIT);
						}
					});
				},
				{ rootMargin: "-200px" }
			);

			//Get the trigger element and observe it
			loadMoreTrigger = document.getElementById("loadMore");
			observer.current.observe(loadMoreTrigger);
		}

		//Cleanup function to remove observer
		return () => {
			if (loadMoreTrigger) observer.current.unobserve(loadMoreTrigger);
		};
	}, [showMore]);

	return (
		<>
			<PageHeader />
			<div className="grid-wrapper pageHeader-wrapper aItems-center">
				<div id="pageHeader-title">
					<h1>{header && header.title}</h1>
				</div>
				<div id="pageHeader-desc">
					<p>{header && header.desc}</p>
				</div>
				<div className="grid columnsAutoFree-2 paddingBlock-xsmall gap-small fontColour-lighter">
					<div className="flex jContent-center text-vertical text-vertical-alt">View by Sector</div>
					<div className="paddingInline-none projectSector-links">
						{data && (
							<>
								<Link to={`/projects/sector/all`} className="sectorLink sectorLink-all">
									<img src={allSectorImg} alt="All Sectors represented by Dakota Hotel Facade" className="object-fit" />
									<div className="sectorLink-title">All</div>
								</Link>
								{data.sectorList.items.map((sector) => (
									<Link key={`link-${sector.sys.id}`} to={`/projects/sector/${sector.slug}`} className={`sectorLink sectorLink-${sector.name.toLowerCase()}`}>
										<img src={sector.image.url} alt={`${sector.name} Background Image`} className="object-fit" />
										<div className="sectorLink-title">{sector.name}</div>
									</Link>
								))}
							</>
						)}
					</div>
				</div>
			</div>
			<div className="grid-wrapper projects-wrapper">
				{projects.length ? (
					projects.map((project, idx) => {
						const delay = 200 * (idx / ((querySkip + QUERY_LIMIT) / QUERY_LIMIT) + 1);
						return (
							<ProjectItem
								key={`project-${project.sys.id}`}
								id={project.sys.id}
								title={project.title}
								slug={project.slug}
								image={project.heroImage.url}
								completed={project.completion}
								location={project.location}
								animationDelay={delay}
							/>
						);
					})
				) : (
					<p>There are no projects for this sector</p>
				)}
			</div>
			{loading && <div className="loadingSpinner"></div>}
			{showMore && <div id="loadMore"></div>}
		</>
	);
};

export default Projects;
