//NODE MODULES
import React, { useEffect, useState } from "react";

//KPP COMPONENTS
import { LinkUnderline, SplitTitle } from "../helpers";

//HELPERS
import useScrollBlock from "../../helpers/scrollBlock";

const ProjectHeader = ({ title, sector, location, details, heroImage }) => {
	const [loaded, setLoaded] = useState(false);
	const [titleLoaded, setTitleLoaded] = useState(false);
	const [blockScroll, allowScroll] = useScrollBlock();

	useEffect(() => {
		setLoaded(true);

		const titleTimer = setTimeout(() => {
			setTitleLoaded(true);
			allowScroll();
		}, 1000 + 0.4 * title.length * 100);

		return () => {
			clearTimeout(titleTimer);
		};
	}, []);

	return (
		<div className="headerWrapper">
			<div className={`headerWrapper-background ${titleLoaded ? "shrink" : ""}`}>
				<img src={heroImage.url} alt={heroImage.title} className={`${titleLoaded ? "hide" : ""}`} />
			</div>
			<section className={`container headerContent ${titleLoaded ? "shrink" : ""}`}>
				<div className={`projectTitle`}>
					<h2 className="flex gapCol-medium marginBottom-xsmall paddingLeft-xsmall">
						<div>
							{sector.map((s, idx) => {
								return (
									<>
										<LinkUnderline side="top" to={`/projects/${s.slug}`} text={s.name} />
										{idx < sector.length - 1 && <span>, </span>}
									</>
								);
							})}
						</div>
						<span>//</span>
						<span>{location}</span>
					</h2>
					<h1 className={`projectTitle-projectName ${loaded ? "animate" : ""}`}>
						<SplitTitle title={title} />
					</h1>
				</div>
				<div className={`projectDetails`}>
					{details.map((detail, idx) => {
						const labelStyle = { animationDelay: (0.5 * idx) / 10 + 0.5 + "s" };
						const valueStyle = { animationDelay: (0.5 * idx) / 10 + 0.7 + "s" };
						return (
							<React.Fragment key={idx}>
								<div className={`flexVert projectDetail-value ${loaded ? "animate" : ""}`} style={valueStyle}>
									{detail.value}
								</div>
								<span className={`projectDetail-label ${loaded ? "animate" : ""}`} style={labelStyle}>
									{detail.label}
								</span>
							</React.Fragment>
						);
					})}
				</div>
			</section>
			<section className="imageWrapper">
				<img src={heroImage.url} alt={heroImage.title} className={`${titleLoaded ? "animate" : ""}`} />
			</section>
		</div>
	);
};

export default ProjectHeader;
