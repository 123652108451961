import { useRecoilValue } from "recoil";
import { AppNav } from ".";
import { ReactComponent as LogoSimple } from "../../assets/img/logo/kpp-logo-full.svg";
import { LinkUnderline } from "../helpers";
import AppNavMobile from "./AppNavMobile";

//Atoms
import { showMobileMenuAtom } from "../../atoms/layout";

const AppHeader = () => {
	const showMobileMenu = useRecoilValue(showMobileMenuAtom);

	return (
		<>
			<header className="appHeader" data-mobilenav-visible={showMobileMenu}>
				<LinkUnderline side="right" destination="/" text={<LogoSimple />} linkClasses="appHeader-logo" />
				<AppNav />
			</header>
			<AppNavMobile />
		</>
	);
};

export default AppHeader;
