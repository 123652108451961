import { PageHeader } from "../components/helpers";

const About = () => {
	return (
		<>
			<PageHeader />

			<div className="content-width headerPadding marginBlock-large">
				<article>
					<h1 class="marginBottom-large">Privacy Policy</h1>
					<h2 className="fontColour-creamDarker">INTRODUCTION</h2>
					<p>
						Kilmartin Plowman & Partners Ltd. is committed to ensuring your privacy and personal information is protected; it is the data controller of your personal
						information and is responsible for complying with data protection laws.
					</p>
					<p>
						This Privacy Statement describes how we process (collect, use and share) personal information. By providing your personal information, you acknowledge that
						we may process it as set out in this policy.
					</p>
					<p>
						The following topics are presented in this <i>Privacy Statement</i>:
						<ul>
							<li>Collecting personal information</li>

							<li>Collecting other information</li>

							<li>Using and retaining personal information</li>

							<li>Protecting personal information</li>

							<li>Your rights to access personal information</li>

							<li>Changes to this privacy statement</li>

							<li>Contact us</li>
						</ul>
					</p>
					<h2 className="fontColour-creamDarker">COLLECTING PERSONAL INFORMATION</h2>
					<p>
						Kilmartin Plowman & Partners Ltd. has a legitimate interest to process personal data relating to decision makers, budget holders, event participants and
						other interest groups.
					</p>
					<p>The collection and use of information from several sources is essential to our company's ability to provide our services.</p>
					<p>
						We collect information that identifies you or could reasonably be used to identify you as an individual, which we refer to here as Personal Data. The
						personal data that we may collect includes company contact details, such as name, job title, postal address, telephone numbers, email addresses and
						financial and transaction data, such as credit or debit card numbers used in relation to our services.
					</p>
					<p>We will only use information that we collect about you lawfully in accordance with GDPR.</p>
					<p>
						In addition, the privacy statement will also be included within each email sent by Kilmartin Plowman & Partners Ltd. employees as well as being linked on
						the Kilmartin Plowman & Partners Ltd. websites.
					</p>
					<p>
						We also collect information from data provided by you as a delegate or participant attending any Kilmartin Plowman & Partners Ltd. event (or by someone
						acting on your behalf).
					</p>
					<p>
						We will collect personal data from you when you register interest on our booth at a tradeshow event or conference, or directly from the Kilmartin Plowman &
						Partners Ltd. websites, via an online form when you download any registration content or obtain a quotation request.
					</p>
					<p>
						By filling in an online form on the Kilmartin Plowman & Partners Ltd. websites, you will consent to the collection, use, disclosure, storage, and processing
						of personal data in accordance with this Privacy Statement and if opted in, agree to receive marketing updates on behalf of Kilmartin Plowman & Partners
						Ltd.
					</p>
					<p>
						We will collect personal data received directly from related individuals by email, telephone call or by business card at networking events, conferences or
						from third parties acting on behalf of the individuals and supplier invoices.
					</p>
					<p>
						Data is also gathered from publicly available sources such as (but not limited to); corporate social media, related business platforms, e-newsletter
						respondents and posted job vacancies.
					</p>
					<h2 className="fontColour-creamDarker">HOW WE PROCESS PERSONAL DATA</h2>
					<p>
						The personal data we collect is stored in various internal databases, including our CRM databases. These systems are all owned, controlled, and hosted by
						Kilmartin Plowman & Partners Ltd.
					</p>
					<p>
						We hold personal data only for as long as there is legitimate reason to hold the data, or for as long as is necessary thereafter for legislative purposes.
					</p>
					<h2 className="fontColour-creamDarker">HOW WE USE PERSONAL DATA</h2>
					<p>
						We use personal data to:
						<ul>
							<li>Email or contact you about related products or services we think may be of interest to you</li>
							<li>Send you marketing communications via the post and via electronic means such as e-newsletters and email correspondence.</li>
							<li>Provide you with information, products, or services that you request from us.</li>
							<li>Respond to your enquiries and provide customer support.</li>
							<li>Process your orders, and to issue your invoices by post or emails and to collect payments and to make payments to suppliers where applicable.</li>
							<li>Notify you about changes to our services.</li>
							<li>Improve and modify our services.</li>
						</ul>
					</p>
					<h2 className="fontColour-creamDarker">DATA DISCLOSURE</h2>
					<p>
						The data we gather about you will never be sold on to a third party. In certain circumstances, we may need to disclose your data to the appropriate third
						parties if required to do so by law or an authorised government body.
					</p>
					<h2 className="fontColour-creamDarker">MARKETING</h2>
					<p>
						We would like to send you information about products and services of ours which may be of interest to you. The products and services will always relate to
						products and services of Kilmartin Plowman & Partners Ltd.
					</p>
					<p>
						If you sign up for the marketing communication, your transactional and usage data in addition will be analysed by Kilmartin Plowman & Partners Ltd. to
						tailor commercial communication specifically to your preferences.
					</p>
					<p>You have a right at any time to stop us from contacting you for marketing, and you may opt out at a later date.</p>
					<p>
						If you no longer wish to be contacted, for marketing purposes, please <a href="mailto:architects@kpp-leeds.co.uk?subject=Unsubscribe">click here</a> to
						unsubscribe.
					</p>
					<h2 className="fontColour-creamDarker">LEGITIMATE INTEREST</h2>
					<p>The GDPR states, ‘the processing of personal data for direct marketing purposes may be regarded as carried out for a legitimate interest’.</p>
					<p>
						We process your personal data for our legitimate business interests. e.g., direct marketing, modifying or improving our services.{" "}
						<a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/legitimate-interests/">
							Click here
						</a>
						to learn more about ‘legitimate interest’.
					</p>
					<p>
						You have the right to object to this processing if you wish and if you wish to do so, please{" "}
						<a href="mailto:architects@kpp-leeds.co.uk?subject=Personal Marketing Data">click here</a>.
					</p>
					<h2 className="fontColour-creamDarker">SECURITY</h2>
					<p>
						The security of your data is important to us. We have implemented technology and security policies, rules and measures designed to protect the personal data
						under our control, both on or off-line from unauthorised access, improper use, alteration, unlawful or accidental destruction and accidental loss.
					</p>
					<p>
						Offline, all our personal data is restricted in our offices. Only employees of Kilmartin Plowman & Partners Ltd. are granted access to such information.
						Please remember, however, that no data transmission over the internet can be guaranteed as secure.
					</p>
					<p>
						There are exceptions where, for those opted in to receive marketing updates, we upload email addresses to our third-party marketing email automation
						platform for email marketing.
					</p>
					<p>
						These service providers are provided only with the personal data that they need to perform their services and are not permitted to use or disclose your
						personal data for other purposes without your prior authorisation.
					</p>
					<h2 className="fontColour-creamDarker">ACCESS TO YOUR INFORMATION AND CORRECTION</h2>
					<p>You have the right to request a copy of the information that we hold about you.</p>
					<p>
						If you would like a copy of some or all the personal data we hold, please email Kilmartin Plowman & Partners Ltd.{" "}
						<a href="mailto:architects@kpp-leeds.co.uk?subject=Personal Data">here</a> or write to the following address:{" "}
						<b>GDPR officer, Kilmartin Plowman & Partners Ltd., Lodge House, 12 Town Street, Horsforth, West Yorkshire, LS18 4RJ</b>.
					</p>
					<p>In certain circumstances we reserve the right to charge a reasonable fee to comply with your request.</p>
					<p>We want to make sure that your personal information is accurate and up to date. You may ask us to correct or remove data that is inaccurate.</p>
					<p>
						However, the data will be retained, and processed, for suppression purposes i.e. to ensure that it cannot be added to the database again or to ensure we are
						fulfilling all legislative and contractual requirements.
					</p>
					<h2 className="fontColour-creamDarker">COMPLAINTS</h2>
					<p>Individuals have the right to complain to the Information Commissioner if they believe there is a problem with the way their data is being used.</p>
					<p>
						Follow the link to contact{" "}
						<a href="https://ico.org.uk/concerns/" target="_blank" rel="noreferrer">
							ICO CONCERNS
						</a>
					</p>
					<h2 className="fontColour-creamDarker">DISCLOSURES REQUIRED BY LAW</h2>
					<p>
						Your personal information will be disclosed where we are obliged by law to do so. We may also disclose your personal information where we are allowed by law
						to protect or enforce our rights or the rights of others and for the detection and prevention of crimes, such as fraud.
					</p>
					<h2 className="fontColour-creamDarker">COOKIES</h2>
					<p>We use cookies to help make is easier for you to access our websites.</p>
					<p>Cookies are pieces of information that a website transfers to your computer's hard disk so that the website can remember who you are.</p>
					<p>
						Most websites use cookies. Cookies cannot be used by themselves to identify you. Cookies are also used to collect information about how visitors use our
						site. We use the information to compile reports and to help us improve the site.
					</p>
					<p>
						The cookies collect information in an anonymous form, including the number of visitors on the site, where visitors have come to the site from and the pages
						they visited. We will always ask you about your preference for storing cookies, however, there may be occasions where cookies will be necessary for our
						websites to work. In these circumstances, by using our website, you agree that we can place these types of cookies on your computer. You may prevent us from
						storing a cookie on your computer by setting your browser so that it will not accept cookies.
					</p>
					<h2 className="fontColour-creamDarker">OTHER WEBSITES</h2>
					<p>
						Kilmartin Plowman & Partners Ltd.'s websites may contain links to other sites. When you choose to visit those sites, we cannot assume responsibility for any
						other site's content or information handling practices. We encourage you to review each site's privacy policy prior to entering into transactions with the
						third party-linked site.
					</p>
					<h2 className="fontColour-creamDarker">CHANGES TO PRIVACY POLICY</h2>
					<p>
						Any changes we may make to this privacy policy in the future will be posted on this page. We encourage you to check this privacy policy from time to time
						for any updates or changes to the privacy policy. If we would like to use your previously collected personal data for different purposes than those we
						notified you about at the time of collection, we will provide you with notice and, where required by law, seek your consent before using your personal data
						for a new or unrelated purpose.
					</p>
					<p>We may process your personal data without your knowledge or consent where required by applicable law or regulation.</p>
					<p>This policy was last reviewed on 04 February 2022.</p>
					<h2 className="fontColour-creamDarker">PRIVACY SUPPORT</h2>
					<p>Please contact us if you have any questions about our privacy policy or information we hold about you by emailing architects@kpp-leeds.co.uk</p>
					<p>
						Or write to us at:
						<div className="fontWeight-bold">
							GDPR Officer
							<br />
							Kilmartin Plowman & Partners Ltd.
							<br />
							Lodge House
							<br />
							12 Town Street
							<br />
							Horsforth
							<br />
							West Yorkshire
							<br />
							LS18 4RJ
						</div>
					</p>
				</article>
			</div>
		</>
	);
};

export default About;
