export const navRoutes = {
	home: "/home",
	about: "/about",
	av: "/kpp-av",
	avExt: "https://www.kppav.co.uk/",
	projects: "/projects",
	projectsSector: "sector/:sectorId",
	projectItem: "project/:projectId",
	news: "/news",
	newsItem: "/news/:newsId",
	contact: "/contact",
	privacy: "/privacy-policy",
};
