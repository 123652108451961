import { PageHeader } from "../components/helpers";

const About = () => {
	return (
		<>
			<PageHeader />

			<div className="content-width headerPadding marginTop-xlarge">
				<section className="summary plusPattern marginBottom-xlarge text-center">
					<p>
						KPP Architects are an <b>award-winning architectural practice;</b> based in Leeds since 1969, providing full Architectural services in connection with a
						wide range of projects throughout the U.K.
					</p>

					<p>
						Led by the practice’s directors, our <b>collaborative approach</b> empowers the collective dialogue that has shaped the practice for over 60 years, a
						dialogue which has <b>the client at its centre</b> and the experience of the KPP team supporting it.
					</p>

					<p>
						The Practice offers a <b>comprehensive range of services</b> including development strategies, master-planning, full architectural service, interior design
						and visuals. We have experience in multiple building sectors to <b>deliver small scale and multi-million pound projects</b> for Industrial, Heritage, Health
						Care, Retail & Leisure, Workplace, Education and Residential projects. We combine our design skill and technical knowledge to deliver high quality projects
						for our clients, being <b>innovative and progressive</b> in our approach, from concept layout to construction detailing.
					</p>

					<p>
						Our award-winning architecture is supported by a commercial and considered approach to tackling a client’s brief, this involves multi-disciplinary,
						collaborative working, within a wider team framework, liaising closely with the client to ensure their aspirations and objectives are being delivered.
					</p>
				</section>

				<section>
					<header>
						<h2>Meet the Team</h2>
					</header>
					<p>
						We believe in positive working relationships with our clients, the wider project team and in-house. This forms the foundation to fully integrated and
						coordinated projects. To this end, all projects are assigned a designated team of experienced personnel, led by a Director, providing consistency for the
						client and wider project team.
					</p>
					<p>
						At the outset of a project we establish the key elements of the client’s objectives to deliver an efficient, comprehensive project. We consider factors
						which may affect the deliverability of the scheme, impacting on layout, density, design, viability, sustainability etc. Often this requires
						multi-disciplinary involvement in the early stages of a project, engaging with Statutory Authorities and other professionals in a staged approach, to manage
						the expenditure of up-front costs and to ensure the long-term goals of the project are met effectively and successfully.
					</p>
				</section>

				<section className="grid-wrapper columns-3 marginTop-large marginBottom-xlarge paddingInline-none">
					<div className="backgroundColor-kppCream padding-medium">
						<header className="marginBottom-medium">
							<h3>Rachel Slater, Director</h3>
							<span>BA (Hons) BArch RIBA</span>
						</header>
						<p>Rachel began her career at KPP as a graduate architect in 2001, rising though the practice to become a Director in 2008.</p>
						<p>
							Her architectural flair is balanced by a depth of understanding of detailing and construction methods, ensuring a project can be realised, even at
							concept stage. Rachel is an extremely versatile architect, having worked in most sectors (industrial, residential, healthcare, education, conservation,
							etc) and her knowledge base is extensive.
						</p>
						<ul className="marginTop-medium">
							<li>Batchelor of Arts Degree in Architecture</li>
							<li>Batchelor of Architecture Degree</li>
							<li>Member of the Architects Registration Board</li>
							<li>Chartered member of the Royal Institute of British Architects (RIBA)</li>
						</ul>
					</div>

					<div className="backgroundColor-kppCream padding-medium">
						<header className="marginBottom-medium">
							<h3>James Winter, Director</h3>
							<span>MCIAT</span>
						</header>
						<p>
							As Director of KPP Architects Ltd James has the responsibility for the design procurement of major development projects from conception through to
							completion and final handover.
						</p>
						<p>
							Having worked at KPP Architects for 30 years his project managing experience covers a diverse range of construction projects, including industrial,
							commercial, residential and retail.
						</p>
						<ul className="marginTop-medium">
							<li>HNC/HND Building Studies</li>
							<li>Member of Chartered Institute of Architectural Technology</li>
						</ul>
					</div>

					<div className="backgroundColor-kppCream padding-medium">
						<header className="marginBottom-medium">
							<h3>James McDermott, Director</h3>
							<span>BSc Arch Tech, MCIAT</span>
						</header>
						<p>James has worked at KPP for over 25 years and became a director in 2016.</p>
						<p>
							He has experience in project management, design and delivering projects to site. This includes a wide range of projects, including industrial,
							commercial, residential and conservation projects, contributing to a diverse understanding of the built environment and a broad palette of construction
							design.
						</p>
						<ul className="marginTop-medium">
							<li>Batchelor of Science Degree in Architecture Technology</li>
							<li>Member of Chartered Institute of Architectural Technology</li>
						</ul>
					</div>
				</section>
			</div>
		</>
	);
};

export default About;
