import { useRef, useEffect, useState } from "react";

const LinkUnderline = ({ side, destination, external, text, wrapperClasses, linkClasses, active }) => {
	const underline = useRef();

	const [lineSize, setLineSize] = useState(0);

	useEffect(() => {
		if (!underline.current) return;

		const resizeObserver = new ResizeObserver(() => {
			const { width, height } = underline.current.getBoundingClientRect();
			if (side === "top" || side === "bottom") setLineSize(Math.floor(width));
			if (side === "left" || side === "right") setLineSize(Math.floor(height));
		});

		resizeObserver.observe(underline.current);

		return () => resizeObserver.disconnect();
	});

	return (
		<a
			href={destination}
			target={external ? "_blank" : "_self"}
			rel={external && "noreferrer"}
			className={`underline-target underline-wrapper underline-${side} ${wrapperClasses}`}
			data-active-location={active}>
			<div className={linkClasses}>{text}</div>
			<div className="underline" ref={underline}>
				<div className="underline-value">{lineSize}</div>
				<div className="underline-left"></div>
				<div className="underline-right"></div>
			</div>
		</a>
	);
};

export default LinkUnderline;
