import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";

import { ProjectItem } from "../projects";

//GRAPHQL
const QUERY = gql`
	query MoreProjects($sectorName: String!) {
		projectCollection(limit: 4, where: { sector: { slug: $sectorName } }, order: sys_firstPublishedAt_DESC) {
			items {
				sys {
					id
				}
				title
				slug
				completion
				location
				heroImage {
					url
				}
			}
		}
	}
`;

const MoreProjects = ({ title, sector, current }) => {
	const { data, errors, loading } = useQuery(QUERY, {
		variables: {
			sectorName: sector,
		},
	});

	const [projects, setProjects] = useState([]);

	useEffect(() => {
		if (data && data.projectCollection.items.length) {
			const newProjects = data.projectCollection.items;
			const filteredProjects = newProjects.filter((p) => p.sys.id != current);

			if (filteredProjects.length > 3) filteredProjects.pop();
			setProjects(filteredProjects);
		}
	}, [data]);

	return (
		<section className="moreItems-wrapper circlePattern">
			<div className="paddingTop-large text-center fontSize-medium">{title}</div>
			<div className="grid-wrapper columns-3 content-width paddingTop-medium paddingBottom-large">
				{projects.length ? (
					projects.map((project, idx) => {
						const delay = 200 * (idx + 1);
						return (
							<ProjectItem
								key={`project-${project.sys.id}`}
								id={project.sys.id}
								title={project.title}
								slug={project.slug}
								image={project.heroImage.url}
								completed={project.completion}
								location={project.location}
								animationDelay={delay}
							/>
						);
					})
				) : (
					<p>No other projects in this Sector</p>
				)}
			</div>
		</section>
	);
};

export default MoreProjects;
