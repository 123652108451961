import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LinkImage = ({ slug, title, image }) => {
	const underline = useRef();

	const [lineSize, setLineSize] = useState(0);

	useEffect(() => {
		if (!underline.current) return;

		const resizeObserver = new ResizeObserver(() => {
			const { width } = underline.current.getBoundingClientRect();
			setLineSize(Math.floor(width));
		});

		resizeObserver.observe(underline.current);

		return () => resizeObserver.disconnect();
	});

	return (
		<Link to={slug} className={`link-image underline-target`}>
			<div className="image">
				<img src={image} alt={`${title} Background`} />
			</div>
			{/* <div className="title underline-wrapper underline-top"> */}
			<div className="title">
				{title}
				{/* <div className="underline" ref={underline}>
					<div className="underline-value">{lineSize}</div>
					<div className="underline-left"></div>
					<div className="underline-right"></div>
				</div> */}
			</div>
		</Link>
	);
};

export default LinkImage;
