//Node Modules
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//My Components
import { LinkUnderline } from "../helpers";

//3rd Party Components

//Atoms

//Helpers
import { navRoutes } from "../../config/nav";
import Hamburger from "./Hamburger";

const AppNav = ({ role }) => {
	const { pathname } = useLocation();

	const [currentLocation, setCurrentLocation] = useState("/");

	useEffect(() => {
		const path = `/${pathname.split("/")[1]}`;
		// console.log("Path ::", path);
		setCurrentLocation(path);
	}, [pathname]);

	return (
		<>
			<nav className={`appNav appNav-${role}`}>
				<LinkUnderline side="bottom" destination={navRoutes.about} text="About" active={currentLocation.startsWith(navRoutes.about)} />
				<LinkUnderline side="bottom" destination={`${navRoutes.projects}/sector/all`} text="Projects" active={currentLocation.startsWith(navRoutes.projects)} />
				<LinkUnderline side="bottom" destination={navRoutes.avExt} text="KPP AV" active={currentLocation.startsWith(navRoutes.avExt)} external />
				<LinkUnderline side="bottom" destination={navRoutes.news} text="News" active={currentLocation.startsWith(navRoutes.news)} />
				<LinkUnderline side="bottom" destination={navRoutes.contact} text="Contact" active={currentLocation.startsWith(navRoutes.contact)} />
			</nav>
			<Hamburger />
		</>
	);
};

export default AppNav;
