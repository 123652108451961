//NODE MODULES
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ImageViewer from "awesome-image-viewer";
// - Contentful
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
// - GraphQL
import { useQuery, gql } from "@apollo/client";

//KPP COMPONENTS
import { ProjectHeader, MoreProjects, Quote } from "../components/project";

//3rd PARTY COMPONENTS
import { HiMagnifyingGlassPlus } from "react-icons/hi2";

//HELPERS
import useScrollBlock from "../helpers/scrollBlock";

//GRAPHQL QUERY
const QUERY = gql`
	query Project($slug: String!, $isPreview: Boolean = false) {
		projectCollection(limit: 1, preview: $isPreview, where: { slug: $slug }) {
			items {
				sys {
					id
				}
				title
				summary {
					json
				}
				description1 {
					json
				}
				description2 {
					json
				}
				description3 {
					json
				}
				description4 {
					json
				}
				sectorCollection(limit: 12) {
					items {
						sys {
							id
						}
						name
						slug
					}
				}
				size
				value
				completion
				location
				client
				contractor
				heroImage {
					url
					title
				}
				imagesCollection {
					items {
						url
						title
					}
				}
				partnersCollection {
					items {
						logo {
							url
							title
						}
					}
				}
				quote {
					quote
					author
				}
			}
		}
	}
`;

const Project = () => {
	const [blockScroll] = useScrollBlock();
	const params = useParams();

	const [project, setProject] = useState(null);
	const [pageMeta, setPageMeta] = useState({
		title: "KPP Architects",
		desc: "Default website description",
	});
	const [projectDetails, setProjectDetails] = useState(null);

	const { data, errors, loading } = useQuery(QUERY, {
		variables: {
			slug: params.projectId,
			isPreview: process.env.REACT_APP_CONTENT_PREVIEW === "true" ? true : false,
		},
	});

	useEffect(() => {
		blockScroll();
	}, []);

	useEffect(() => {
		if (data && data.projectCollection) {
			setProject(data.projectCollection.items[0]);

			const pageData = data.projectCollection.items[0];
			setPageMeta({
				title: pageData.title,
				desc: documentToPlainTextString(pageData.summary.json),
			});

			const projectDetailsArray = [];
			pageData.client && projectDetailsArray.push({ label: "Client", value: pageData.client });
			pageData.contractor && projectDetailsArray.push({ label: "Contractor", value: pageData.contractor });
			pageData.size && projectDetailsArray.push({ label: "Size", value: pageData.size });
			pageData.value && projectDetailsArray.push({ label: "Value", value: pageData.value });
			setProjectDetails(projectDetailsArray);
		}
	}, [data]);

	const viewImages = (idx) => {
		const projectImages = project.imagesCollection.items.map((img) => {
			return { mainUrl: img.url, thumbnailUrl: img.url, description: img.title };
		});
		new ImageViewer({
			images: projectImages,
			currentSelected: idx,
			stretchImages: true,
			isZoomable: false,
		});
	};

	return (
		project && (
			<>
				<Helmet>
					<title>{pageMeta.title} :: KPP Architects</title>
					<meta name="description" content={pageMeta.desc} />
				</Helmet>
				<article className="project marginBottom-xlarge">
					<ProjectHeader
						title={project.title}
						sector={project.sectorCollection.items}
						location={project.location}
						heroImage={project.heroImage}
						details={projectDetails}
					/>
					<div className="projectContent">
						<div className="content-width marginTop-xlarge">
							<section className="summary plusPattern marginBottom-xlarge text-center">{documentToReactComponents(project.summary.json)}</section>
							<section className="grid-wrapper description">
								{/* Row 1 - text + img */}
								<div className="text">{documentToReactComponents(project.description1.json)}</div>
								<div className="imgHover-wrapper" onClick={() => viewImages(0)}>
									<img src={project.imagesCollection.items[0].url} alt={project.imagesCollection.items[0].title} />
									<div className="imgHover">
										<HiMagnifyingGlassPlus />
									</div>
								</div>

								{/* Row 2 - img */}
								<div className="imgHover-wrapper span-col2" onClick={() => viewImages(1)}>
									<img src={project.imagesCollection.items[1].url} alt={project.imagesCollection.items[1].title} />
									<div className="imgHover">
										<HiMagnifyingGlassPlus />
									</div>
								</div>

								{/* Row 3 - img + text */}
								<div className="imgHover-wrapper" onClick={() => viewImages(2)}>
									<img src={project.imagesCollection.items[2].url} alt={project.imagesCollection.items[2].title} />
									<div className="imgHover">
										<HiMagnifyingGlassPlus />
									</div>
								</div>
								<div className="text">{documentToReactComponents(project.description2.json)}</div>

								{/* Row 4 - img */}
								{project.imagesCollection.items[3] && (
									<div className="imgHover-wrapper span-col2" onClick={() => viewImages(3)}>
										<img src={project.imagesCollection.items[3].url} alt={project.imagesCollection.items[3].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}

								{/* Row 5 - text */}
								{project.description3 && <div className="text span-col2 text-center">{documentToReactComponents(project.description3.json)}</div>}

								{/* Row 6 - img */}
								{project.imagesCollection.items[4] && (
									<div className="imgHover-wrapper span-col2" onClick={() => viewImages(4)}>
										<img src={project.imagesCollection.items[4].url} alt={project.imagesCollection.items[4].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}

								{/* Row 7 - text */}
								{project.description4 && <div className="text span-col2 text-center">{documentToReactComponents(project.description4.json)}</div>}

								{/* Row 8 - quote */}
								{project.quote && <Quote quote={project.quote.quote} author={project.quote.author} />}
							</section>
							<section className="grid-wrapper columns-3 overflow-gallery">
								{project.imagesCollection.items[5] && (
									<div className="imgHover-wrapper span-col2" onClick={() => viewImages(5)}>
										<img src={project.imagesCollection.items[5].url} alt={project.imagesCollection.items[5].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[6] && (
									<div className="imgHover-wrapper" onClick={() => viewImages(6)}>
										<img src={project.imagesCollection.items[6].url} alt={project.imagesCollection.items[6].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[7] && (
									<div className="imgHover-wrapper" onClick={() => viewImages(7)}>
										<img src={project.imagesCollection.items[7].url} alt={project.imagesCollection.items[7].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[8] && (
									<div className="imgHover-wrapper" onClick={() => viewImages(8)}>
										<img src={project.imagesCollection.items[8].url} alt={project.imagesCollection.items[8].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[9] && (
									<div className="imgHover-wrapper" onClick={() => viewImages(9)}>
										<img src={project.imagesCollection.items[9].url} alt={project.imagesCollection.items[9].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[10] && (
									<div className="imgHover-wrapper" onClick={() => viewImages(10)}>
										<img src={project.imagesCollection.items[10].url} alt={project.imagesCollection.items[10].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[11] && (
									<div className="imgHover-wrapper span-col2" onClick={() => viewImages(11)}>
										<img src={project.imagesCollection.items[11].url} alt={project.imagesCollection.items[11].title} />
										<div className="imgHover">
											<HiMagnifyingGlassPlus />
										</div>
									</div>
								)}
								{project.imagesCollection.items[12] &&
									project.imagesCollection.items.slice(12).map((p, idx) => (
										<div className="imgHover-wrapper" onClick={() => viewImages(idx + 12)} key={p.title}>
											<img src={p.url} alt={p.title} />
											<div className="imgHover">
												<HiMagnifyingGlassPlus />
											</div>
										</div>
									))}
							</section>
							{project.partnersCollection.items.length > 0 && (
								<section className="partners marginTop-xlarge text-center">
									{/* <h3>Should we put a title here? Parterns? In Combination With?</h3> */}
									<div className="flex columns-2 gap-xlarge jContent-center aItems-center marginTop-medium">
										{project.partnersCollection.items.map((partner) => (
											<img src={partner.logo.url} alt={partner.logo.title} />
										))}
									</div>
								</section>
							)}
						</div>
					</div>
				</article>
				<MoreProjects title={`More ${project.sectorCollection.items[0].name} Projects`} sector={project.sectorCollection.items[0].slug} current={project.sys.id} />
			</>
		)
	);
};

export default Project;
