import { Link } from "react-router-dom";
import { DateTime } from "luxon";

//Assets
import { ReactComponent as CicleLink } from "../../assets/img/circle-link.svg";

const ProjectItem = ({ id, styleClass, title, slug, image, completed, location, animationDelay }) => {
	return (
		<div className="projectItem-wrapper">
			<Link to={`/projects/project/${slug}`} className={`projectItem project-reveal ${styleClass}`} style={{ animationDelay: `${animationDelay}ms` }}>
				<div className="projectItem-image overflow-hidden">
					<img src={`${image}?w=500`} alt={`Thumbnail for ${title}`} />
				</div>
				<div className="flex jContent-spaceBetween aItems-center padding-small title-wrapper">
					<div className="title">
						<div className="subTitle fontSize-small flex gap-small">
							{completed && (
								<>
									<span>{DateTime.fromISO(completed).toFormat("MMM yyyy")}</span>
									<span>//</span>
								</>
							)}
							<span>{location}</span>
						</div>
						<span className="limitLines-1">{title}</span>
					</div>
					<CicleLink />
				</div>
			</Link>
		</div>
	);
};

export default ProjectItem;
