const SplitTitle = ({ title, role }) => {
	return (
		<span aria-label={title} role={role}>
			{title.split("").map((letter, idx) => {
				const style = { animationDelay: (0.4 * idx) / 10 + "s" };
				return (
					<span key={idx} style={style} aria-hidden="true">
						{letter}
					</span>
				);
			})}
		</span>
	);
};

export default SplitTitle;
