//NODE MODULES
import { useEffect, useState, useRef } from "react";
import { useLazyQuery, gql } from "@apollo/client";

//KPP COMPONENTS
import { PageHeader } from "../components/helpers";
import { NewsItem } from "../components/news";

//GRAPHQL
const QUERY_LIMIT = 18;
const QUERY = gql`
	query News($isPreview: Boolean = false, $queryLimit: Int, $querySkip: Int) {
		newsCollection(preview: $isPreview, limit: $queryLimit, skip: $querySkip, order: alternativePublishDate_DESC) {
			items {
				sys {
					id
					publishedAt
				}
				title
				slug
				heroImage {
					url
					title
				}
				project {
					sys {
						id
					}
					title
				}
				alternativePublishDate
			}
		}
	}
`;

const News = () => {
	const observer = useRef(null);

	//Local State
	const [querySkip, setQuerySkip] = useState(0);
	const [news, setNews] = useState([]);

	//GraphQL Setup
	const [getNews, { data, errors, loading }] = useLazyQuery(QUERY, {
		variables: {
			queryLimit: QUERY_LIMIT,
			querySkip: querySkip,
			isPreview: process.env.REACT_APP_CONTENT_PREVIEW === "true" ? true : false,
		},
	});

	useEffect(() => {
		getNews();
	}, []);

	useEffect(() => {
		let newsData = [];

		if (data && data.newsCollection) {
			newsData = data.newsCollection.items;
		}

		if (data && newsData.length === 0) {
			const loadMoreTrigger = document.getElementById("loadMore");
			observer.current.unobserve(loadMoreTrigger);
		}

		setNews((prevState) => [...prevState, ...newsData]);
	}, [data]);

	// useEffect(() => {
	// 	let loadMoreTrigger = null;

	// 	//create new instance and pass a callback function
	// 	observer.current = new IntersectionObserver(
	// 		(entries) => {
	// 			entries.forEach((entry) => {
	// 				const intersecting = entry.isIntersecting;
	// 				if (intersecting) {
	// 					getNews();
	// 					setQuerySkip((prevState) => prevState + QUERY_LIMIT);
	// 				}
	// 			});
	// 		},
	// 		{ rootMargin: "-300px" }
	// 	);

	// 	//Get the trigger element and observe it
	// 	loadMoreTrigger = document.getElementById("loadMore");
	// 	observer.current.observe(loadMoreTrigger);

	// 	//Cleanup function to remove observer
	// 	return () => {
	// 		if (loadMoreTrigger) observer.current.unobserve(loadMoreTrigger);
	// 	};
	// }, []);

	return (
		<>
			<PageHeader />
			<div className="grid-wrapper pageHeader-wrapper aItems-center">
				<div id="pageHeader-title">
					<h1>News</h1>
				</div>
				<div id="pageHeader-desc">
					<p className="text-maxWidth"></p>
				</div>
			</div>
			{/* <div className="grid-wrapper projects-wrapper" style={{ minHeight: "90vh" }}> */}
			<div className="grid-wrapper projects-wrapper">
				{news.length ? (
					news.map((newsItem, idx) => {
						const delay = 200 * (idx / ((querySkip + QUERY_LIMIT) / QUERY_LIMIT) + 1);
						return (
							<NewsItem
								key={`newsItem-${newsItem.sys.id}`}
								id={newsItem.sys.id}
								slug={newsItem.slug}
								title={newsItem.title}
								image={newsItem.heroImage.url}
								date={newsItem.alternativePublishDate ? newsItem.alternativePublishDate : newsItem.sys.publishedAt}
								project={newsItem.project}
								animationDelay={delay}
							/>
						);
					})
				) : (
					<p>No News at the moment, please check back soon to stay up to date with KPP.</p>
				)}
			</div>
			{loading && <div className="loadingSpinner"></div>}
			<div id="loadMore"></div>
		</>
	);
};

export default News;
