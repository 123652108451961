//Node Modules
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

//My Components
import { AppFooter, AppHeader } from "./components/layout";

//3rd Party Components

//Atoms

//Helpers

//Styles
import "./assets/css/main.css";

//Messages
import "./i18n";

const App = () => {
	return (
		<div className="app">
			<AppHeader />
			<main>
				<Outlet />
			</main>
			<AppFooter />
			<ScrollRestoration />
		</div>
	);
};

export default App;
