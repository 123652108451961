import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import { mapOptions } from "../../config/contact/map";
import markerIcon from "../../assets/img/contact/map/marker.svg";

const containerStyle = {
	width: "100%",
	height: "100%",
};

const center = {
	lat: 53.83701,
	lng: -1.64186,
};

const zoom = 14;

const Map = () => {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyBoYHaJoOL9nv3jTeNstTMRmJOih0F0wHY",
	});

	const [map, setMap] = React.useState(null);

	const onLoad = React.useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	return isLoaded ? (
		<GoogleMap mapContainerStyle={containerStyle} options={mapOptions} center={center} zoom={zoom} onLoad={onLoad} onUnmount={onUnmount}>
			<Marker
				title="KPP Architects Horsforth Office"
				position={center}
				icon={{
					url: markerIcon,
					scaledSize: new window.google.maps.Size(80, 120),
					origin: new window.google.maps.Point(0, 0),
					anchor: new window.google.maps.Point(40, 120),
				}}
				draggable={false}
			/>
		</GoogleMap>
	) : (
		<></>
	);
};

export default React.memo(Map);
