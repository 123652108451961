const mapStyle = [
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				hue: "#ffbb00",
			},
			{
				saturation: "100",
			},
			{
				lightness: "20",
			},
			{
				gamma: 1,
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				hue: "#ffad00",
			},
			{
				saturation: "36",
			},
			{
				lightness: "30",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [
			{
				hue: "#FFC200",
			},
			{
				saturation: -61.8,
			},
			{
				lightness: 45.599999999999994,
			},
			{
				gamma: 1,
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "all",
		stylers: [
			{
				hue: "#FF0300",
			},
			{
				saturation: -100,
			},
			{
				lightness: 51.19999999999999,
			},
			{
				gamma: 1,
			},
		],
	},
	{
		featureType: "road.local",
		elementType: "all",
		stylers: [
			{
				hue: "#FF0300",
			},
			{
				saturation: -100,
			},
			{
				lightness: 52,
			},
			{
				gamma: 1,
			},
		],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				saturation: "-60",
			},
			{
				lightness: "40",
			},
		],
	},
];

export const mapOptions = {
	styles: mapStyle,
};
